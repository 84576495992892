<template>

<div>

        <a href="#" @click.prevent="visible = true">
               <Money :money="value" />
            </a>

    <a-modal :visible="visible" title="投标利润" :footer='null' width='800px' @cancel="visible = false">

   <a-table
        bordered
        :data-source="[...bidList, bidAll]"
        :loading="loading"
        :pagination="false"
      >
            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index === bidList.length ? '合计' : index + 1 }}
                </template>
            </a-table-column>

        <a-table-column title="类别" align='center'>
          <template slot-scope="text">
            {{text.cate }}
          </template>
        </a-table-column>


        <a-table-column title="拟投标价(元)" align='right' >
          <template slot-scope="text">
              <span style="color: #1890ff">
                {{typeof text.preBidPrice === 'number' ? text.preBidPrice.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>
                <a-table-column title="承担主体" >
         <template slot-scope="text">
               <span>
                {{text.respSubject}}
              </span>
          </template>
        </a-table-column>

        <a-table-column title="投标控制预算(元)"  align='right'>
          <template slot-scope="text">
                        <span style="color: #1890ff">
                {{typeof text.bidBudget === 'number' ? text.bidBudget.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>
                <a-table-column title="投标利润(元)" align='right' >
          <template slot-scope="text">
                         <span style="color: #1890ff">
                {{typeof text.bidProfit === 'number' ? text.bidProfit.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>
        
        <a-table-column title="备注">
         <template slot-scope="text">
                       <span>
                {{text.remark}}
              </span>
          </template>
        </a-table-column>

      </a-table>

    </a-modal>


    </div>
</template>



<script>

import request from '@/api/request'

function fetchDetail(params) {
    return request({
        url:  '/market-service/epc/view/profit/queryById',
        params,
    })
}


export default {

    props: ['id', 'value'],
 
    data() {
        return {
            visible: false,
            loading: false,
            bidList: [],
        }
    },
    


    computed: {
      bidAll() {
        let preBidPrice = 0;
        let bidBudget = 0;
        let bidProfit = 0;
        this.bidList.forEach(item => {
            if (typeof item.preBidPrice === 'number') {
              preBidPrice += item.preBidPrice;
            }
               if (typeof item.bidBudget === 'number') {
              bidBudget += item.bidBudget;
            }
               if (typeof item.bidProfit === 'number') {
              bidProfit += item.bidProfit;
            }
        });
        return {
          preBidPrice,
          bidBudget,
          bidProfit,
        }
      },

    },

    watch: {

        visible() {
          if (this.visible) {
                  this.loading = true;
        fetchDetail({
            id: this.id,
        }).then(res => {
          if (res) {
            this.bidList = res.bidList || []
          }
        }).finally(() => {
          this.loading = false;
        })
          }
        }

  
    },



}
</script>