<template>
  <div>

        <Pane />

    <a-card class="container">
      <div class="card" >
        <div class="item">
          <div class="title">
            <span>历史利润(万)</span>
            <span class="extra">
              <HistoryList :list='historyAnnualData' />
            </span>
          </div>
          <div
            class="value"
          >
                   <Money :money="historyProfit" />
          </div>

        </div>
        <div class="item">
          <div class="title">
            <span>当年总利润(万)</span>
            <span class="extra">
              <YearList :list='statistic' />
            </span>
          </div>
          <div
            class="value"
          >
                   <Money :money="statisticProfit" />
          </div>

        </div>
        <div class="item" >
          <div class="title">
               <span>当月完成利润(万)</span>
            <span class="extra">
              <MonthList  title='当月完成利润' :list="monthObj.prjCheckList ? monthObj.prjCheckList : []" />
            </span>
          </div>
          <div
            class="value"
          >
              <Money :money="monthObj.profit" />
          </div>
        </div>
      </div>

      <Padding />

      <a-form-model layout="inline" :colon="false" :model="form" @keyup.enter.native="query">

            <a-form-model-item>
              <a-input
                placeholder="设计编号"
                v-model="form.designCode"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-input placeholder="合同名称" v-model="form.name" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
                    total,
                    current,
                    pageSize,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }"
        rowKey="id"
      >

        <a-table-column title="类别" align='center'>
          <template slot-scope="text">
              <span v-if="text.type === 'selfOper'">自营类</span>
              <span v-if="text.type === 'admin'">管理类</span>
          </template>
        </a-table-column>

        <a-table-column title="设计编号" data-index='designCode' align='center' />
        <a-table-column title="合同名称" data-index='name' />

        <a-table-column title="合同金额(万)"  align='right'>
          <template slot-scope="text">
             <Money :money="text.contractAmount" />
          </template>
        </a-table-column>
                <a-table-column title="设计费(万)" align='right'>
          <template slot-scope="text">
             <Money :money="text.designAmt" />
          </template>
        </a-table-column>
                <a-table-column title="工程款(万)"  align='right'>
          <template slot-scope="text">
             <Project :id="text.id" :value="text.projectAmt" />
          </template>
        </a-table-column>
                <a-table-column title="投标利润(万)"  align='right'>
          <template slot-scope="text">
            <Bid :id="text.id" :value="text.bidProfit" />
          </template>
        </a-table-column>
                <a-table-column title="实施控制预算利润(万)"  align='right'>
          <template slot-scope="text">
            <Budget :id="text.id" :value="text.budgetProfit" />
          </template>
        </a-table-column>
                <a-table-column title="实时利润(万)"  align='right'>
          <template slot-scope="text">
             <Money :money="text.actualProfit" />
          </template>
        </a-table-column>

        <a-table-column title="利润率" data-index='profitRate' align='right'  />

        <a-table-column title="当前进度" align='right'>
          <template slot-scope="text">
             {{ text.currentProgress }}%
          </template>
        </a-table-column>

        <a-table-column title="当期利润(万)" align='right'>
          <template slot-scope="text">
             <Money :money="text.currentProfit" />
          </template>
        </a-table-column>

        <a-table-column align="center" title="操作" width="120px">
          <template slot-scope="text">
            <div>
              <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/detail')"
                @click.prevent="$router.push($route.path + '/detail?id=' + text.id)"
              >查看</a>
                    <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="$router.push($route.path + '/edit?id=' + text.id)"
              >编辑</a>
              </a-space>
            </div>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import HistoryList from "./history-list.vue";
import MonthList from "./month-list.vue";
import YearList from "./year-list.vue";
import moment from 'moment'

import Bid from './bid.vue'
import Budget from './budget.vue'
import Project from './project.vue'

function fetchHistoryAnnualData() {
  return request({
    url: "/market-service/epc/view/profit/historyAnnualData/list"
  });
}

function fetchStatistic({year}) {
  return request({
    url: "/market-service/epc/prg/check/bus/list?date=" + year
  });
}

function fetchList(params) {
  return request({
       url: '/market-service/epc/view/profit/list',
    params
  });
}

export default {
  mixins: [ watermark],

  components: {
    MonthList,
    YearList,
    HistoryList,
    Bid,
    Budget,
    Project
  },

  data() {
    return {
      historyAnnualData: [],
      historyProfit: 0,
      statistic: [],
      statisticProfit: 0,
      month: moment().format("YYYY-MM"),

      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    monthObj() {
        const item = this.statistic.find(item => item.date === this.month);
        console.log('item',item)
        return item ? item : {}
    }
  },

  mounted() {
    fetchStatistic({
      year: new Date().getFullYear()
    }).then(res => {
      this.statistic = Object.freeze(res);
      if (Array.isArray(res) && res.length > 0) {
        this.statisticProfit = res[res.length -1 ].profit
      }
    })
    fetchHistoryAnnualData().then(res => {
      this.historyAnnualData = res;
      res.forEach(element => {
        this.historyProfit = this.historyProfit + element.profit;
      });
    })
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;
      this.loading = true;

      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...this.form,
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize ? res.totalSize : 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },


    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

  }
};
</script>
<style lang="less" scoped>
.card {
  display: flex;

  .item {
    border: 2px solid #f0f0f0;
    margin-right: 12px;
    padding: 12px;
    border-radius: 2px;
    min-width: 240px;
    position: relative;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .extra {
        margin-left: auto;
        cursor: pointer;
      }
    }

    .value {
      font-size: 2em;
      color: #1890ff;
    }

    .setting {
      position: absolute;
      bottom: 12px;
      right: 12px;
      cursor: pointer;
    }
  }

  .active {
    border-color: #1890ff;
  }
}
</style>