<template>
    <div>

            <a href="#" @click.prevent="visible = true">
               <Money :money="value" />
            </a>

    <a-modal :visible="visible" title="实施控制预算利润" :footer='null' width='800px' @cancel="visible = false">


            <a-table
        bordered
          :data-source="[...bidBudgetList, budgetAll]"
        :loading="loading"
        :pagination="false"
        rowKey="num"
      >

            <a-table-column title="序号" width="50px" align="center">
                <template slot-scope="text, row, index">
                    {{ index === bidBudgetList.length  ? '合计' : text.num }}
                </template>
            </a-table-column>

        <a-table-column title="类别" align='center'>
            <template slot-scope="text">
                          <span >
                            {{text.cate}}
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="合同价(元)"  >
          <template slot-scope="text">
                                     <span style="color: #1890ff">
                {{typeof text.contractPrice === 'number' ? text.contractPrice.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="承担主体" >
         <template slot-scope="text">
               <span >
                {{text.respSubject}}
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="实施控制预算(元)"  >
          <template slot-scope="text">
     
                                                  <span style="color: #1890ff">
                {{typeof text.impBidBudget === 'number' ? text.impBidBudget.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="目标利润(元)"  >
          <template slot-scope="text">
                                                                <span style="color: #1890ff">
                {{typeof text.targetProfit === 'number' ? text.targetProfit.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>

                        <a-table-column title="实际利润(元)"  >
          <template slot-scope="text">
                                                                              <span style="color: #1890ff">
                {{typeof text.actualProfit === 'number' ? text.actualProfit.toLocaleString() : '--'}}
              </span>
          </template>
        </a-table-column>

                <a-table-column title="备注">
         <template slot-scope="text">
                       <span >
                        {{text.remark}}
              </span>
          </template>
        </a-table-column>
   
      </a-table>


    </a-modal>

    </div>
</template>



<script>

import request from '@/api/request'

function fetchDetail(params) {
    return request({
        url:  '/market-service/epc/view/profit/queryById',
        params,
    })
}


export default {

   props: ['id', 'value'],
 

    data() {
        return {
            loading: false,
            visible: false,
            bidBudgetList: [],
        }
    },

    computed: {
      budgetAll() {
            let contractPrice = 0;
        let impBidBudget = 0;
        let targetProfit = 0;
        let actualProfit = 0;

                this.bidBudgetList.forEach(item => {
            if (typeof item.contractPrice === 'number') {
              contractPrice += item.contractPrice;
            }
               if (typeof item.impBidBudget === 'number') {
              impBidBudget += item.impBidBudget;
            }
               if (typeof item.targetProfit === 'number') {
              targetProfit += item.targetProfit;
            }
                  if (typeof item.actualProfit === 'number') {
              actualProfit += item.actualProfit;
            }
        });

        return {
          num: '#',
          contractPrice,
          impBidBudget,
          targetProfit,
          actualProfit
        }
      }
    },

  watch: {
       visible() {
        this.loading = true;
        fetchDetail({
            id: this.id,
        }).then(res => {
          if (res) {
            this.bidBudgetList = res.bidBudgetList || []
          }
        }).finally(() => {
          this.loading = false;
        })
    },
  },



}
</script>