<template>
  <span>
    <span @click="visible = true">
        <a v-if="link" @click.prevent>{{link}}</a>
        <a-icon v-else type="right" />
    </span>

    <a-modal :visible="visible" :title="title" :footer="null" @cancel="visible = false" width='800px'>
<!-- :scroll="{ y: 400 }" -->
    <a-table bordered :dataSource="list" :pagination="false" >

      <a-table-column title="合同编号" data-index="code" />
      <a-table-column title="合同名称" data-index="name"  />

      <!-- <a-table-column title="当期进度" align="right" v-if="detail.view !== 'sum'">
        <template slot-scope="text, row, index">
          <span v-if="index === list.length - 1"></span>
          <span v-else>{{ typeof text.progress === 'number' ? text.progress + '%' : text.progress }}</span>
        </template>
      </a-table-column>

    -->

       <a-table-column title="当期产值(万)" align="right">
        <template slot-scope="text">
             <Money :money="text.income" />
          <!-- <span
            style="color: #1890ff"
          >{{ text.income ? parseFloat(text.income).toLocaleString() : text.income }}</span> -->
        </template>
      </a-table-column>

      <a-table-column title="当期利润(万)" align="right">
        <template slot-scope="text">
              <Money :money="text.profit" />
          <!-- <span
            style="color: #1890ff"
          >{{ text.profit ? parseFloat(text.profit).toLocaleString() : text.profit }}</span> -->
        </template>
      </a-table-column>
    </a-table>

    </a-modal>
  </span>
</template>

<script>

export default {
    props: ['list', 'title', 'link'],
  data() {
    return {
      visible: false,
    };
  },
};
</script>