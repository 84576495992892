<template>
  <span>
    <span @click="visible = true">
      <a-icon type="right" />
    </span>

    <a-modal :visible="visible" title="当年总利润" :footer="null" @cancel="visible = false">
        
      <a-table bordered :dataSource="filteredList" :pagination="false">
        <a-table-column title="月份" width="120px" align="center" data-index="date" />

                <a-table-column title="产值(万)" align="right">
          <template slot-scope="text">
                 <Money :money="text.income" />
          </template>
        </a-table-column>

        <a-table-column title="利润(万)" align="right">
          <template slot-scope="text">
                 <Money :money="text.profit" />
          </template>
        </a-table-column>

        <a-table-column align="center" title="操作" width="160px" fixed="right">
          <template slot-scope="text">
            <MonthList title="项目详情" link='项目详情' :list="text.prjCheckList ? text.prjCheckList : []" />
          </template>
        </a-table-column>
      </a-table>

    </a-modal>
  </span>
</template>

<script>
import MonthList from './month-list.vue'
export default {
    props: ['list'],
    components: {
      MonthList
    },
  data() {
    return {
      visible: false,
    };
  },

  computed: {
    filteredList() {
        return this.list.filter(item => item.date)
    }
  },

  methods: {
    openDetail(text) {
        console.log(text)
    }
  }
};
</script>