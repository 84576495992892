<template>
    <span>
      <span @click="visible = true">
        <a-icon type="right" />
      </span>
  
      <a-modal :visible="visible" title="历史利润" :footer="null" @cancel="visible = false">
        <a-button style="margin-bottom: 5px;" @click="handleAdd">
          新增
        </a-button>
        <a-table bordered :dataSource="filteredList" :pagination="false">
          <a-table-column title="年份" width="120px" align="center">
            <template slot-scope="text">
                <a-input
                  v-if="text.editable"
                  v-model="text.year"
                  size="small"
                />
                <span v-else>
                  {{ text.year }}
                </span>
            </template>
          </a-table-column>  
  
          <a-table-column title="产值(万)" align="right">
            <template slot-scope="text">
                <a-input
                  v-if="text.editable"
                  v-model="text.income"
                  size="small"
                />
                <Money v-else :money="text.income" />
            </template>
          </a-table-column>
  
          <a-table-column title="利润(万)" align="right">
            <template slot-scope="text">
              <a-input
                v-if="text.editable"
                v-model="text.profit"
                size="small"
              />
              <Money v-else :money="text.profit" />
            </template>
          </a-table-column>
          <a-table-column align="center" title="操作" width="120px">
            <template slot-scope="text, row, index">
            <div>
              <a-space>
                <a
                  href="javascript:void(0)"
                  v-if="text.editable"
                  @click="handleSave(text, index)"
                >保存</a>
                <a
                  href="javascript:void(0)"
                  @click="handleDelete(text, index)"
                >删除</a>
                <a
                  href="javascript:void(0)"
                  v-if="!text.editable"
                  @click="showDetail(text)"
                >详情</a>
              </a-space>
            </div>
          </template>
          </a-table-column>
        </a-table>
  
      </a-modal>

      <a-modal :visible="detailVisible" title="当年总利润" :footer="null" @cancel="detailVisible = false">
        
        <a-table bordered :dataSource="statistic" :pagination="false">
          <a-table-column title="月份" width="120px" align="center" data-index="date" />
  
                  <a-table-column title="产值(万)" align="right">
            <template slot-scope="text">
                   <Money :money="text.income" />
            </template>
          </a-table-column>
  
          <a-table-column title="利润(万)" align="right">
            <template slot-scope="text">
                   <Money :money="text.profit" />
            </template>
          </a-table-column>
  
          <a-table-column align="center" title="操作" width="160px" fixed="right">
            <template slot-scope="text">
              <MonthList title="项目详情" link='项目详情' :list="text.prjCheckList ? text.prjCheckList : []" />
            </template>
          </a-table-column>
        </a-table>
  
      </a-modal>
    </span>
  </template>
  
  <script>
  import request from "@/api/request";
  import MonthList from './month-list.vue'

  export function addData(data) {
    return request({
      url: '/market-service/epc/view/profit/historyAnnualData/submit',
      method: 'POST',
      data
    })
  }

  export function deleteData(data) {
    return request({
      url: '/market-service/epc/view/profit/historyAnnualData/delete',
      method: 'POST',
      data
    })
  }

  function fetchStatistic({year}) {
    return request({
      url: "/market-service/epc/prg/check/bus/list?date=" + year
    });
  }
  export default {
    props: ['list'],
    components: {
      MonthList
    },
    data() {
      return {
        visible: false,
        detailVisible: false,
        statistic: []
      };
    },
  
    computed: {
      filteredList() {
          return this.list
      }
    },
  
    methods: {
      handleAdd() {
        const newData = {
          editable: true,
          year: null,
          income: null,
          profit: null
        };
        this.filteredList.push(newData)
      },
      handleSave(text, index) {
        addData({
          income: parseInt(text.income) * 10000,
          profit: parseInt(text.profit) * 10000,
          year: parseInt(text.year)
        }).then(() => {
          this.filteredList.splice(index, 1, {
            income: parseInt(text.income) * 10000,
            profit: parseInt(text.profit) * 10000,
            year: parseInt(text.year),
            editable: false
          })
        })
      },
      handleDelete(text,index) {
        const that = this
        this.$confirm({
          title: "确认要删除吗？",
          onOk() {
            deleteData({
              year: parseInt(text.year)
            }).then(() => {
              that.filteredList.splice(index, 1)
            })
          }
        });
      },
      showDetail(text) {
        console.log(text)
        fetchStatistic({
          year: text.year
        }).then(res => {
          this.statistic = Object.freeze(res).filter(item => item.date);
          this.detailVisible = true;
        })
      }
    }
  };
  </script>